<template>
    <div class="main analytics-page">
        <div class="main-width" v-if="isAuth">
            <h2> Для АПК РК </h2>

            <div v-show="showReport">
                <!-- <a href="#" @click="closeReport()"><i class="fas fa-window-close float-right"></i></a> -->
                <div id="reportContainer">
                
                </div>
            </div>
            <div v-show="!showReport">
                <div class="alert alert-danger py-5" role="alert">
                    <p class="text-center">{{$t("message.no_tariff_analytics")}}</p>
                </div>

            </div>

            <beat-loader 
                v-if="loading" 
                class="loader-center" 
                :color="'#553DB4'" 
                :loading="loading" 
                :size="20" 
                :sizeUnit="'px'" 
                />

        </div>
        <div class="main-width" v-else>
            <div class="alert alert-danger py-5" role="alert">
                <p class="text-center">{{$t("message.isNotAuth")}}</p>
            </div>
        </div>


        <modal
            name="analytics-modal"
            width="100%"
            height="100%"
            :adaptive="true">
            <p>This is a modal</p>
            <button @click="closeModal()">Hide</button>
        </modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as pbi from 'powerbi-client'

    export default {
        name: 'analytics',
        data: () => ({
            items: [],
            favourite: [],
            loading: false,
            showReport: false,

        }),
        mounted() {
            this.onShow();
        },
        beforeRouteUpdate(to, from, next) {
            if (to.params.id) {
                this.showAnalytic('9D4AC005-D3B7-4265-BB06-3C1F082C4CF1');
            }
            next();
        },
        methods: {
            onShow() {
                // this.getItems();
                // if (this.$route.params.id) {
                    this.showAnalytic('9D4AC005-D3B7-4265-BB06-3C1F082C4CF1');
                // }
                // this.getFavourite();
            },
            getFavourite() {
                this.favourite = [];
                this.$store.dispatch("LOAD_ANALYTICS_FAVOURITE")
                    .then((s) => {
                        this.favourite = s.data;
                    })
                    ;
            },
            toggleFavourite(item) {
                if (this.favouriteIds.includes(item.id)) {
                    this.deleteFav(item);
                }
                else {
                    this.saveFav(item);
                }
            },
            saveFav(analytic) {
                this.$store.dispatch("SAVE_ANALYTICS_FAVOURITE", {
                    userId: this.user.id,
                    analytics: analytic
                })
                .then(() => this.getFavourite())
                ;
            },
            deleteFav(item) {
                this.favourite.forEach(el => {
                    if (el.analytics.id === item.id) {
                        this.$store.dispatch("DELETE_ANALYTICS_FAVOURITE", el.id)
                        .then(() => this.getFavourite())
                        ;
                    }
                });
            },
            isFavourite(id) {
                return this.favouriteIds.includes(id);
            },
            getName(i) {
                let name;
                switch(this.$i18n.locale) {
                    case 'kz':
                        name = i.nameKz;
                        break;
                    default:
                        name = i.nameRu;
                }
                return name;
            },
            getDescription(i) {
                let description;
                switch(this.$i18n.locale) {
                    case 'kz':
                        description = i.descriptionKz;
                        break;
                    default:
                        description = i.descriptionRu;
                }
                return description;
            },
            embedPBI(containerId, accessToken, embedUrl, reportId) {
                var permissions = pbi.models.Permissions.All
                var config = {
                    type: 'report',
                    tokenType:  pbi.models.TokenType.Embed,
                    accessToken: accessToken,
                    embedUrl: embedUrl,
                    id: reportId,
                    permissions: permissions,
                    settings: {
                        filterPaneEnabled: true,
                        navContentPaneEnabled: true
                    }
                };

                let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
                var reportContainer = document.getElementById(containerId);
                powerbi.reset(reportContainer);
                var report = powerbi.embed(reportContainer, config);

                report.on("loaded", function() {
                    reportContainer.style.height = "830px";
                });

                report.off("rendered");

                report.on("error", function () {
                    this.report.off("error");
                });
            },
            getAnalytic(id) {
                const params = {entity: "Analytics", uuid: id};
                this.$store.dispatch('CLICKED_LIST', params)
                    .then( a => {
                        if (a.status == 200) {

                            if ( a.data.analyticsTariffList
                                .filter( t => t.dtariffId.id === this.tariff.id).length > 0 ) {

                                this.$store.dispatch('GET_PBI_REPORT', a.data.reportId)
                                    .then( r => {
                                        if (r.status == 200) {
                                            this.loading = false;
                                            this.embedPBI('reportContainer', r.data.embedToken, r.data.embedUrl, r.data.reportId);
                                        }
                                    })
                                    .catch(() => {
                                        this.loading = this.showReport = false;
                                        this.$toast.error(this.$t('message.page_not_found'));
                                    });
                            }
                            else {
                                this.loading = this.showReport = false;
                                this.$toast.error(this.$t("message.no_tariff_analytics"));
                            }
                        }
                    })
                    .catch(() => {
                        this.loading = this.showReport = false;
                        this.$toast.error(this.$t('message.page_not_found'));
                    });
            },
            getItems() {
                this.loading = true;

                const params = {
                    entity: 'Analytics'
                };

                this.$store.dispatch('GET_ITEMS', params) 
                    .then((res) => {
                        this.items = res.data
                        this.loading  = false
                    })
                    ;
            },
            showAnalytic(id) {
                this.loading = this.showReport = true;
                this.getAnalytic(id);
            },
            closeReport() {
                this.showReport = false;
                let c = document.getElementById('reportContainer');
                c.innerHTML = null;
                c.style.height = null;
                if (this.$route.params.id) {
                    this.$router.go(-1);
                }
            },
            showModal() {
                this.$modal.show('analytics-modal');
                // if (screen.width < screen.height) show rotate device icon
            },
            closeModal() {
                this.$modal.hide('analytics-modal');
            },
        },
        computed: {
            ...mapGetters([
                'USER_TARIFF',
                'user_tarif_name',
                'user_tarif_id',
                'user',
                'isAuth'
            ]),
            tariff() {
                return this.USER_TARIFF;
            },
            analytics() {
                return this.items && this.items.filter( i => {
                    if ( i.analyticsTariffList
                            .filter( t => t.dtariffId.id === this.tariff.id).length > 0) {
                            return true;
                        }
                    });
            },
            favoutires() {
                return this.favourite && this.favourite.filter( i => {
                    if ( i.analytics.analyticsTariffList
                            .filter( t => t.dtariffId.id === this.tariff.id).length > 0) {
                            return true;
                        }
                    });
            },
            favouriteIds() {
                return this.favourite && this.favourite.map(el => el.analytics.id);
            }
        },
        watch: {
            isAuth(val, oldVal) {
                if (val === true && oldVal === false){
                    this.onShow();
                }
            }
        }

    }
</script>

<style lang="scss" scoped>
h2 {
    margin-bottom: 40px;
}
.service {
    padding: 25px;
    min-height: 250px;
}
.service__body h2 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 30px;
    line-height: 1.3;
}

.service__img{
    padding: 10px;
    img {
        max-width: 35px;
    }
}

.service .analytics-favourite {
    position: absolute;
    right: 25px;
    bottom: 25px;
    cursor: pointer;
}
</style>